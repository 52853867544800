// LanguageContext.js
import React, { createContext, useState } from "react";

// Criação do contexto
export const LanguageContext = createContext();

// Componente provedor que irá envolver a aplicação
export const LanguageProvider = ({ children }) => {
    const [language, setLanguage] = useState("en");

    // Função para alterar o idioma
    const changeLanguage = (newLanguage) => {
        setLanguage(newLanguage);
    };

    return (
        <LanguageContext.Provider value={{ language, changeLanguage }}>
            {children}
        </LanguageContext.Provider>
    );
};
