import "./Footer.css";
import React, { useMemo, useContext } from "react";
import Logo from "../../assets/imgs/JG-Símbolo+Logotipo_Branco.svg";

import useTranslatedMenuItems from "./Btns-Content";

import { LanguageContext } from "../../context/LanguageContext";
import MenuBtn from './MenuBtn';

const Footer = ({ scrollToComponent }) => {


  const menuItems = useTranslatedMenuItems();

  const action = (address) => {
    if (address !== "Link") {
      scrollToComponent(address);
    } else {
      scrollUp();
    }
  };

  const { language } = useContext(LanguageContext); // Obtém o idioma do contexto

  const textContent = {
    en: {
      navigationTitle: "NAVIGATION",
      specialtiesTitle: "SPECIALTIES",
      specialties: [
        "Software Engineering",
        "Technical Leadership",
        "AI Engineering",
        "DevOps and Cloud Computing",
        "SAAS Building",
        "Project Management",
      ],
      copy: "© 2024 Jacques Gomes.",
    },
    pt: {
      navigationTitle: "NAVEGAÇÃO",
      specialtiesTitle: "ESPECIALIDADES",
      specialties: [
        "Engenharia de Software",
        "Liderança Técnica",
        "Engenharia de IA",
        "DevOps e Cloud Computing",
        "Construção de SAAS",
        "Gestão de Projetos",
      ],
      copy: "© 2024 Jacques Gomes.",
    },
    // Adicione outros idiomas aqui
  };

  const scrollUp = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const memoizedMenuItems = useMemo(() => {
    return menuItems.map((menuItem, index) => (

      <MenuBtn className="footer-menu-btn" action={action} data={menuItem} scrollToComponent={scrollToComponent} />

    ));
  }, [menuItems]);

  return (
    <footer className="footer-background">
      <div className="footer-container header-body-footer-width">
        <div class="footer-container-division">

          <div className="footer-wrap footer-content">
            <div className="footer-logo">
              <img className="footer-img" src={Logo} alt="logo"></img>
            </div>
            <div className="footer-menu-container">
              <div className="footer-menu">
                <h5>NAVEGAÇÃO</h5>
                <div>
                  {memoizedMenuItems}
                </div>
              </div>
              <div className="footer-menu">
                <h5>{textContent[language].specialtiesTitle}</h5>
                <div>
                  {textContent[language].specialties.map((specialty, index) => (
                    <p key={index}>{specialty}</p>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="footer-wrap footer-bottom-container">
          <div className="footer-copy">
            <p>&copy; 2024 Jacques Gomes.</p>
          </div>
          <div className="footer-socials">

            <a
              href="https://github.com/JacquesGomes/"
              target="_blank"
              rel="noreferrer"
              aria-label="site principal do escritório"
            >
              <i className="uil uil-github-alt"></i>
            </a>
            <a
              href="https://www.linkedin.com/in/jacques-gomes-627739240/"
              target="_blank"
              rel="noreferrer"
              aria-label="site principal do escritório"
            >
              <i className="uil uil-linkedin-alt"></i>
            </a>

            <a
              href="https://www.instagram.com/jacquesgomess/"
              target="_blank"
              aria-label="site principal do escritório"
              rel="noreferrer"
            >
              <i className="uil uil-instagram"></i>
            </a>

            <a
              href="https://wa.me/5584994514529"
              aria-label="Whatsapp Icon"
              target="_blank"
              rel="noreferrer"
            >
              <i className="uil uil-whatsapp"></i>
            </a>
            <a
              href="mailto:jacquesgomes66@gmail.com"
              target="_blank"
              aria-label="site principal do escritório"
              rel="noreferrer"
            >
              <i className="uil uil-envelope"></i>
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
