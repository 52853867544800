import { useContext } from "react";
import { LanguageContext } from "../../context/LanguageContext";

const type = "Button";

function useTranslatedMenuItems() {
    const { language } = useContext(LanguageContext);

    const textContent = {
        en: {
            home: "Home",
            portfolio: "Portfolio",

        },
        pt: {
            home: "Início",
            portfolio: "Portfólio",

        },
    };

    const menuItems = [
        {
            label: textContent[language].home,
            type: type,
            to: "inicio",
        },
        {
            label: textContent[language].portfolio,
            type: type,
            to: "portfolio",
        },

    ];

    return menuItems;
}

export default useTranslatedMenuItems;
