import React, { useRef } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import { ThemeProvider } from "@mui/material/styles";
import { theme } from './Theme';
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";

import Header from "./components/shared/Header";
import Footer from "./components/shared/Footer";
import Home from "./components/home/Home";

import Portfolio from "./components/portfolio/Portfolio";

import Servicos from "./components/servicos/Servicos";
import CTA from "./components/CTA/CTA";
import FinalCTA from "./components/CTA/FinalCTA";
import Blog from "./components/blog/Blog";
import Ebook from "./components/Ebook/Ebook";

import "./App.css";
import "./assets/styles/Reset.css";

import NotFound from "./components/notfound/NotFound";

import { LanguageProvider } from "./context/LanguageContext";



function App() {
  const [mode, setMode] = React.useState('dark'); // or 'light'

  // Function to toggle the theme mode
  const toggleTheme = () => {
    setMode((prevMode) => (prevMode === 'dark' ? 'light' : 'dark'));
  };

  const main = process.env.REACT_APP_MAIN;

  const inicio = useRef();
  const portfolio = useRef();
  const blog = useRef();
  const servicos = useRef();
  const ebook = useRef();
  const newsletter = useRef();
  const depoimentos = useRef();

  const scrollToComponent = (refName) => {
    const refs = {
      inicio,
      portfolio,
      servicos,
      blog,
      ebook,
      newsletter,
      depoimentos,
    };

    const selectedRef = refs[refName];

    if (selectedRef && selectedRef.current) {
      var yOffset;
      if (refName === "InicioRef") {
        yOffset = -180;
      } else {
        yOffset = -120;
      }

      const yCoordinate =
        selectedRef.current.getBoundingClientRect().top +
        window.scrollY +
        yOffset;
      window.scrollTo({ top: yCoordinate, behavior: "smooth" });
    }
  };

  return (
    <LanguageProvider>
      <ThemeProvider theme={theme(mode)}>
        <CssBaseline />
        <Router>
          <div className="App">
            <Header scrollToComponent={scrollToComponent} />

            <Routes>
              <Route
                path={main}
                element={
                  <>
                    <div class="body-wrapper">
                      <div ref={inicio}>
                        <Home scrollToComponent={scrollToComponent} />
                      </div>
                       <div ref={portfolio}>
                        <Portfolio />
                      </div>
                      {/*
                      <CTA />
                      <div ref={servicos}>
                        <Servicos />
                      </div>
                      <Blog />
                      <Ebook /> */}
                      {/* <FinalCTA /> */}
                    </div>
                  </>
                }
              ></Route>

              <Route path="*" element={<NotFound />} />
            </Routes>

            <div ref={newsletter}>
              <Footer scrollToComponent={scrollToComponent} />
            </div>

            {/* <div className="whats-btn">
              <a
                href="https://wa.me/5584994514529"
                target="_blank"
                rel="noreferrer"
              >
                <i class="uil uil-whatsapp whats"></i>
              </a>
            </div> */}
          </div>
        </Router>
      </ThemeProvider>
    </LanguageProvider>
  );
}

export default App;
