// theme.js

import { createTheme, alpha } from '@mui/material/styles';
import { deepmerge } from '@mui/utils';

const defaultTheme = createTheme();
const customShadows = [...defaultTheme.shadows];

// Define your primary and secondary color palettes with shades
export const primary = {
    50: 'hsl(230, 96%, 95%)',
    100: 'hsl(230, 96%, 90%)',
    200: 'hsl(230, 96%, 80%)',
    300: 'hsl(230, 96%, 70%)',
    400: 'hsl(230, 96%, 60%)', // main
    500: 'hsl(230, 96%, 50%)',
    600: 'hsl(230, 96%, 40%)',
    700: 'hsl(230, 96%, 30%)',
    800: 'hsl(230, 96%, 20%)',
    900: 'hsl(230, 96%, 10%)',
};

export const secondary = {
    50: 'hsl(264, 84%, 95%)',
    100: 'hsl(264, 84%, 90%)',
    200: 'hsl(264, 84%, 80%)',
    300: 'hsl(264, 84%, 70%)',
    400: 'hsl(264, 84%, 60%)', // main
    500: 'hsl(264, 84%, 50%)',
    600: 'hsl(264, 84%, 40%)',
    700: 'hsl(264, 84%, 30%)',
    800: 'hsl(264, 84%, 20%)',
    900: 'hsl(264, 84%, 10%)',
};

export const gray = {
    50: 'hsl(220, 35%, 97%)',
    100: 'hsl(220, 30%, 94%)',
    200: 'hsl(220, 20%, 88%)',
    300: 'hsl(220, 20%, 80%)',
    400: 'hsl(220, 20%, 65%)',
    500: 'hsl(220, 20%, 42%)',
    600: 'hsl(220, 20%, 35%)',
    700: 'hsl(220, 20%, 25%)',
    800: 'hsl(220, 30%, 6%)',
    900: 'hsl(220, 35%, 3%)',
};

export const getDesignTokens = (mode) => {
    customShadows[1] =
        mode === 'dark'
            ? 'hsla(220, 30%, 5%, 0.7) 0px 4px 16px 0px, hsla(220, 25%, 10%, 0.8) 0px 8px 16px -5px'
            : 'hsla(220, 30%, 5%, 0.07) 0px 4px 16px 0px, hsla(220, 25%, 10%, 0.07) 0px 8px 16px -5px';

    return {
        palette: {
            mode,
            primary: {
                light: primary[200],
                main: '#3254fc', // Your primary main color
                dark: primary[700],
                contrastText: '#ffffff',
                ...(mode === 'dark' && {
                    light: primary[300],
                    main: '#3254fc',
                    dark: primary[700],
                    contrastText: '#ffffff',
                }),
            },
            secondary: {
                light: secondary[200],
                main: '#834bef', // Your secondary main color
                dark: secondary[700],
                contrastText: '#ffffff',
                ...(mode === 'dark' && {
                    light: secondary[300],
                    main: '#834bef',
                    dark: secondary[700],
                    contrastText: '#ffffff',
                }),
            },
            background: {
                default: '#f7f7f7',
                paper: gray[50],
                ...(mode === 'dark' && {
                    default: '#0c0c0c',
                    paper: '#1c1c1c',
                }),
            },
            text: {
                primary: gray[800],
                secondary: gray[600],
                ...(mode === 'dark' && {
                    primary: '#f7f7f7',
                    secondary: '#c1c1c1',
                }),
            },
            divider: mode === 'dark' ? alpha(gray[700], 0.6) : alpha(gray[300], 0.4),
            action: {
                hover: alpha(gray[200], 0.2),
                selected: alpha(gray[200], 0.3),
                ...(mode === 'dark' && {
                    hover: alpha(gray[600], 0.2),
                    selected: alpha(gray[600], 0.3),
                }),
            },
        },
        typography: {
            fontFamily: 'Roboto, "Barlow", "Red Hat Display", sans-serif',
            h1: {
                fontFamily: 'Barlow, Roboto, sans-serif',
                fontWeight: 700,
                fontSize: defaultTheme.typography.pxToRem(48),
                lineHeight: 1.2,
                letterSpacing: -0.5,
            },
            h2: {
                fontFamily: 'Barlow, Roboto, sans-serif',
                fontWeight: 700,
                fontSize: defaultTheme.typography.pxToRem(36),
                lineHeight: 1.2,
            },
            h3: {
                fontFamily: 'Barlow, Roboto, sans-serif',
                fontWeight: 700,
                fontSize: defaultTheme.typography.pxToRem(30),
                lineHeight: 1.2,
            },
            h4: {
                fontFamily: 'Roboto, "Barlow", "Red Hat Display", sans-serif',
                fontSize: defaultTheme.typography.pxToRem(24),
                fontWeight: 600,
                lineHeight: 1.5,
            },
            h5: {
                fontFamily: 'Roboto, "Barlow", "Red Hat Display", sans-serif',
                fontSize: defaultTheme.typography.pxToRem(20),
                fontWeight: 600,
            },
            h6: {
                fontFamily: 'Roboto, "Barlow", "Red Hat Display", sans-serif',
                fontSize: defaultTheme.typography.pxToRem(18),
                fontWeight: 600,
            },
            subtitle1: {
                fontFamily: 'Roboto, "Barlow", "Red Hat Display", sans-serif',
                fontSize: defaultTheme.typography.pxToRem(18),
            },
            subtitle2: {
                fontFamily: 'Roboto, "Red Hat Display", sans-serif',
                fontSize: defaultTheme.typography.pxToRem(18),
                fontWeight: 500,
            },
            body1: {
                fontFamily: 'Roboto, "Red Hat Display", sans-serif',
                fontSize: defaultTheme.typography.pxToRem(18),
            },
            body2: {
                fontFamily: 'Roboto, "Red Hat Display", sans-serif',
                fontSize: defaultTheme.typography.pxToRem(16),
                fontWeight: 400,
            },
            caption: {
                fontFamily: 'Roboto, "Barlow", "Red Hat Display", sans-serif',
                fontSize: defaultTheme.typography.pxToRem(16),
                fontWeight: 400,
            },
        },
        shape: {
            borderRadius: 8,
        },
        shadows: customShadows,
    };
};

// Now create the theme using the getDesignTokens function
export const theme = (mode) => createTheme(getDesignTokens(mode));
