// Header.js
import React, { useState, useEffect, useMemo, useContext } from "react";
import { Link } from "react-router-dom";
import MenuBars from "../../assets/imgs/menu-bars.svg";
import { useNavigate } from "react-router-dom";
import Menu from "./Menu";
import Logo from "../../assets/imgs/JG-Símbolo_Branco.svg";
import "./Header.css";
import useTranslatedMenuItems from "./Btns-Content";
import MenuBtn from './MenuBtn';
import MenuIcon from "@mui/icons-material/Menu";
import LanguageIcon from "@mui/icons-material/Language";
import { Select, MenuItem } from "@mui/material";
import { LanguageContext } from "../../context/LanguageContext"; // Importa o contexto

const Header = ({ scrollToComponent }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const { language, changeLanguage } = useContext(LanguageContext); // Usa o contexto do idioma

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const action = (address) => {
    if (address !== "Link") {
      scrollToComponent(address);
    } else {
      scrollUp();
    }
  };

  const scrollUp = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const navigate = useNavigate();

  const menuItems = useTranslatedMenuItems();

  const memoizedMenuItems = useMemo(() => {
    return menuItems.map((menuItem, index) => (
      <div key={index}>
        <MenuBtn action={action} data={menuItem} scrollToComponent={scrollToComponent} />
      </div>
    ));
  }, [menuItems]);

  const main = process.env.REACT_APP_MAIN;

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      if (scrollY > 50) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleClick = async () => {
    await navigate('/'); // Navigate to the main route
    scrollToComponent("portfolio"); // Scroll to the portfolio section
  };

  // Handle language change
  const handleLanguageChange = (event) => {
    changeLanguage(event.target.value);
  };


  const textContent = {
    en: {
      contact: "Get in Touch",
    },
    pt: {
      contact: "Contato",
    },
  };

  const { contact } = textContent[language];

  return (
    <div className={`main-header ${isScrolled ? 'scrolled' : ''} header-wrapper`}>
      <div className="header-container header-body-footer-width">
        <div className="header-img-div">
          <div className="logo-link header-img">
            <Link to={main} onClick={scrollUp}>
              <img className="logo-link header-img" src={Logo} alt="logo imagem" />
            </Link>
          </div>
        </div>
        <nav>
          {memoizedMenuItems}

          <Select
            value={language}
            onChange={handleLanguageChange}
            startAdornment={<LanguageIcon sx={{ marginRight: 1 }} />} 
            sx={{ color: "white",  minWidth: 120, fontSize: '14px', textTransform: 'uppercase', height: '44px'}}
            MenuProps={{
              disableScrollLock: true, // Prevents body overflow issues
            }}
          >
            <MenuItem value="en">English</MenuItem>
            <MenuItem value="pt">Português</MenuItem>
          </Select>

          <a className="main-btn " href="https://wa.me/5584994514529" target="_blank" rel="noreferrer">
            {contact}
          </a>
        </nav>

        <div className="menu-toggle" style={{display: 'flex', alignItems: 'center', gap: '20px'}}>
          <Select
            value={language}
            onChange={handleLanguageChange}
            startAdornment={<LanguageIcon sx={{ marginRight: 1 }} />}
            sx={{ color: "white", minWidth: 120, fontSize: '14px', textTransform: 'uppercase', height: '44px' }}
            MenuProps={{
              disableScrollLock: true, // Prevents body overflow issues
            }}
          >
            <MenuItem value="en">English</MenuItem>
            <MenuItem value="pt">Português</MenuItem>
          </Select>
          <button className="menu-toggle" onClick={toggleSidebar}>
            <img src={MenuBars} alt="Menu Bars" />
          </button>
        </div>
      </div>

      <Menu
        scrollToComponent={scrollToComponent}
        toggleSidebar={toggleSidebar}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      />
    </div>
  );
};

export default Header;
