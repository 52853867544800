import React, { useContext, useState, useEffect } from "react";
import "./Home.css";
import Perfil from "../../assets/imgs/1s.webp";
import Logo from "../../assets/imgs/JG-Símbolo+Logotipo_Branco.svg";
import { LanguageContext } from "../../context/LanguageContext";
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

function Home({ scrollToComponent }) {
  const { language } = useContext(LanguageContext);

  const [showScrollButton, setShowScrollButton] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY === 0) {
        setShowScrollButton(true);
      } else {
        setShowScrollButton(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const textContent = {
    en: {
      headline: "Building Powerful, AI-Powered Tech Solutions",
      description:
        "Hello, Jacques here! I am a Tech Lead and Software Engineer with deep experience in every stage of the software development lifecycle, combining advanced technical skills with strong leadership.",
      contactButton: "Get in Touch",
      resumeButton: "Resume"
    },
    pt: {
      "headline": "Construindo Soluções Tecnológicas Avançadas",
      "description":
        "Olá, sou Jacques! Como Tech Lead e Engenheiro de Software com experiência em todas as etapas do ciclo de desenvolvimento de software, trago expertise técnica avançada e liderança eficaz para alcançar resultados de alto impacto.",
      "contactButton": "Entre em Contato",
      "resumeButton": "Baixar CV"
    }
,
  };

  const { headline, description, contactButton, resumeButton } = textContent[language];

  return (
    <div className="home-wrapper">
      <div className="shapes-wrapper">

        <div className="shape-small"></div>
        <div className="shape-small-1"></div>

        <div className="shape-small-2"></div>
        <div className="home-container">
          <div className="home-content slide-in">
            <h1>{headline}</h1>
            <p>{description}</p>
            <div className="home-socials">
              <a
                href="https://github.com/JacquesGomes/"
                target="_blank"
                rel="noreferrer"
                aria-label="site principal do escritório"
              >
                <i className="uil uil-github-alt"></i>
              </a>
              <a
                href="https://www.linkedin.com/in/jacques-gomes-627739240/"
                target="_blank"
                rel="noreferrer"
                aria-label="site principal do escritório"
              >
                <i className="uil uil-linkedin-alt"></i>
              </a>

              <a
                href="https://www.instagram.com/jacquesgomess/"
                target="_blank"
                aria-label="site principal do escritório"
                rel="noreferrer"
              >
                <i className="uil uil-instagram"></i>
              </a>

              <a
                href="https://wa.me/5584994514529"
                aria-label="Whatsapp Icon"
                target="_blank"
                rel="noreferrer"
              >
                <i className="uil uil-whatsapp"></i>
              </a>

              <a
                href="mailto:jacquesgomes66@gmail.com"
                target="_blank"
                aria-label="site principal do escritório"
                rel="noreferrer"
              >
                <i className="uil uil-envelope"></i>
              </a>
            </div>
            <div className="home-btn-wrapper">
              <a
                className="main-btn"
                href="https://wa.me/5584994514529"
                target="_blank"
                rel="noreferrer"
              >
                {contactButton}
              </a>
              <a
                className="main-btn-alt"
                href="https://www.linkedin.com/in/jacques-gomes-627739240/"
                target="_blank"
                rel="noreferrer"
              >
                {resumeButton}
              </a>
            </div>
          </div>
          <div className="half-image">
            <div className="overlay"></div>
            <img className="slide-in-right" src={Perfil} alt="Perfil"></img>
          </div>
        </div>
        {showScrollButton && (
          <button
            className="scroll-icon-btn"
            onClick={() => scrollToComponent('portfolio')}
          >
            <ArrowDownwardIcon className="scroll-icon" />
          </button>
        )}
      </div>
    </div>
  );
}

export default Home;
