import React, { useContext, useState, useEffect } from "react";
import { Card, CardMedia, Box, Typography, IconButton } from "@mui/material";
import LaunchIcon from "@mui/icons-material/Launch";
import Portfolio from "./portfolio.json";
import { LanguageContext } from "../../context/LanguageContext";

const ProjectShowcase = () => {
  const { language } = useContext(LanguageContext); // Obtém o idioma do contexto
  const [projects, setProjects] = useState([]);

  const textContent = {
    en: {
      title: "Explore My Portfolio",
    },
    pt: {
      title: "Conheça o meu Portfólio",
    },
    // Adicione outros idiomas aqui, se necessário
  };

  useEffect(() => {
    // Define os projetos com base no idioma
    setProjects(Portfolio[language]);
  }, [language]);

  return (
    <>
      <Box className="portfolio-header"
        sx={{ textAlign: "center", paddingTop: "80px", paddingBottom: "40px" }}
      >
        <h1>{textContent[language].title}</h1>
      </Box>
      <Box
        display="flex"
        flexWrap="wrap"
        gap={3}
        justifyContent="center"
        sx={{ paddingBottom: "100px" }}
      >
        {projects.map((project, index) => (
          <Box
            key={index}
            component="a"
            href={project.liveLink}
            target="_blank"
            rel="noopener noreferrer"
            sx={{
              textDecoration: "none", // Remove underline do link
              color: "inherit", // Mantém a cor original do texto
            }}
          >
            <Card
              sx={{
                position: "relative",
                width: 300,
                height: 300,
                overflow: "hidden",
                borderRadius: 2,
                boxShadow: 3,
                cursor: "pointer",
              }}
            >
              {/* Imagem do projeto */}
              <CardMedia
                component="img"
                image={project.image}
                alt={project.title}
                sx={{
                  width: "100%",
                  height: "100%",
                  transition: "transform 0.3s ease",
                  "&:hover": { transform: "scale(1.1)" },
                }}
              />

              {/* Sobreposição */}
              <Box
                sx={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  backgroundColor: "rgba(0, 0, 0, 0.6)",
                  color: "#fff",
                  opacity: 0,
                  transition: "opacity 0.3s ease",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                  "&:hover": { opacity: 1 },
                }}
              >
                <Typography variant="h6" component="div" gutterBottom>
                  {project.title}
                </Typography>
                <Typography variant="body2" gutterBottom>
                  {project.description}
                </Typography>
                <IconButton
                  href={project.liveLink}
                  target="_blank"
                  rel="noopener"
                  aria-label={`Open ${project.title}`}
                  sx={{ color: "#fff" }}
                >
                  <LaunchIcon fontSize="large" />
                </IconButton>
              </Box>
            </Card>
          </Box>
        ))}
      </Box>
    </>
  );
};

export default ProjectShowcase;
